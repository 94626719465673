/**
 *
 * App
 *
 */

import React, { useEffect } from "react";
import * as Router from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useInjectReducer } from "../../utils/injectReducer";

import { useInjectSaga } from "../../utils/injectSaga";

import reducer from "./reducer";
import saga from "./saga";

import {
  makeSelectAppUserToken,
  makeSelectAppUserDetails,
  makeSelectAppCheckoutJourney,
  makeSelectAppIsLoading,
  makeSelectAppIsPageReady,
} from "./selectors";
import { checkLoginStatus, logout } from "./actions";

import LoadingIndicator from "../../components/LoadingIndicator";
import Header from "../../components/header";
import Footer from "../../components/footer";

import Home from "../Home";
// Static Pages
import PrivacyPolicy from "../PrivacyPolicy";
import CookiePolicy from "../CookiePolicy";
import Terms from "../Terms";
import Faq from "../Faq";

// Login/ Register Flow
import Login from "../LoginPage";
import Register from "../RegisterPage";
import RequestEmailVerify from "../RequestEmailVerify";
import Verify from "../VerifyPage";

import ForgotPassword from "../ForgotPasswordPage";
import ResetPassword from "../ResetPasswordPage";

// Post Login Pages
import Profile from "../profile";
import UpdateProfile from "../UpdateProfilePage";
import ChangePassword from "../ChangePasswordPage";
import Cart from "../Cart";

function App({
  dCheckLoginStatus,
  dLogout,
  userToken,
  userDetails,
  checkoutJourney,
  isLoading,
  isPageReady,
}) {
  useInjectReducer({ key: "app", reducer });
  useInjectSaga({ key: "app", saga });

  useEffect(() => {
    if (!userToken) {
      dCheckLoginStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  return (
    <div className="App">
      <ToastContainer autoClose={5000} />
      <LoadingIndicator isLoading={isLoading || !isPageReady} />
      <div className="account-bg">
        <Header
          logout={dLogout}
          isPageReady={isPageReady}
          isLogin={!!userToken}
          name={userDetails && userDetails.firstName}
          showCart={checkoutJourney && checkoutJourney.inProgress}
          profilePic={userDetails.profilePic}
        />
        <Router.Switch>
          <Router.Route
            path="/"
            exact
            render={(props) => (
              <Home
                {...props}
                isPageReady={isPageReady}
                isLogin={!!userToken && userDetails && !!userDetails.firstName}
              />
            )}
          />          
          <Router.Route
            path="/login"
            exact
            render={(props) => (
              <Login
                {...props}
                isPageReady={isPageReady}
                isLogin={!!userToken && userDetails && !!userDetails.firstName}
              />
            )}
          />
          <Router.Route
            path="/register"
            exact
            render={(props) => (
              <Register
                {...props}
                isPageReady={isPageReady}
                isLogin={!!userToken && userDetails && !!userDetails.firstName}
              />
            )}
          />
          <Router.Route
            path="/request-email-verify"
            exact
            render={(props) => (
              <RequestEmailVerify
                {...props}
                isPageReady={isPageReady}
                isLogin={!!userToken && userDetails && !!userDetails.firstName}
              />
            )}
          />
          <Router.Route
            path="/verify"
            exact
            render={(props) => (
              <Verify
                {...props}
                isPageReady={isPageReady}
                isLogin={!!userToken && userDetails && !!userDetails.firstName}
              />
            )}
          />
          <Router.Route
            path="/forgot-password"
            exact
            render={(props) => (
              <ForgotPassword
                {...props}
                isPageReady={isPageReady}
                isLogin={!!userToken && userDetails && !!userDetails.firstName}
              />
            )}
          />
          <Router.Route
            path="/reset-password"
            exact
            render={(props) => (
              <ResetPassword
                {...props}
                isPageReady={isPageReady}
                isLogin={!!userToken && userDetails && !!userDetails.firstName}
              />
            )}
          />
          <Router.Route
            path="/change-password"
            exact
            render={(props) => (
              <ChangePassword
                {...props}
                isPageReady={isPageReady}
                isLogin={!!userToken && userDetails && !!userDetails.firstName}
              />
            )}
          />
          <Router.Route
            path="/cart"
            exact
            render={(props) => (
              <Cart
                {...props}
                isPageReady={isPageReady}
                isLogin={!!userToken && userDetails && !!userDetails.firstName}
              />
            )}
          />
          <Router.Route
            path="/profile"
            exact
            render={(props) => (
              <Profile
                {...props}
                isPageReady={isPageReady}
                isLogin={!!userToken && userDetails && !!userDetails.firstName}
                userDetails={userDetails}
              />
            )}
          />
          <Router.Route
            path="/update-profile"
            exact
            render={(props) => (
              <UpdateProfile
                {...props}
                isPageReady={isPageReady}
                isLogin={!!userToken && userDetails && !!userDetails.firstName}
                userDetails={userDetails}
              />
            )}
          />
        </Router.Switch>
        <Footer />
      </div>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  userToken: makeSelectAppUserToken(),
  userDetails: makeSelectAppUserDetails(),
  checkoutJourney: makeSelectAppCheckoutJourney(),
  isLoading: makeSelectAppIsLoading(),
  isPageReady: makeSelectAppIsPageReady(),
});

function mapDispatchToProps(dispatch) {
  return {
    dCheckLoginStatus: () => dispatch(checkLoginStatus()),
    dLogout: () => dispatch(logout()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(App);
