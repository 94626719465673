import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

const Footer = () => {
    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    });

    /* Method that will show back-to-top icon after a specific scrollable */
    const isSticky = (e) => {
        const backtotop = document.querySelector(".back-to-top");
        const scrollTop = window.scrollY;
        scrollTop >= 250
                ? backtotop.classList.add("active")
                : backtotop.classList.remove("active");
    };
    return (
            <div>
                <footer id="footer">
                    <div className="footer-newsletter">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-6">
                                    <h4>
                                        Join us in our journey                                       
                                    </h4>
                                    <p>
                                        Even though we would be focused on regular product updates,
                                        news and, promotions; the founders would be engaging users in
                                        their head-space through some media. Enroll here to read that
                                        bit.
                                    </p>
                                    <form action="" method="post">
                                        <input type="email" name="email" />
                                        <input type="submit" value="Subscribe" />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
            
                                                   
                </footer>
            
                <NavLink
                    to="/home"
                    className="back-to-top d-flex align-items-center justify-content-center"
                    >
                    <i className="bi bi-arrow-up-short"></i>
                </NavLink>
            </div>
            );
};
export default Footer;
