import { PureComponent, createRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { uploadMeeting } from "../../containers/App/actions";
import { toast } from "react-toastify";
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import "./newMeeting.css";

const languages = [
  // {
  //   code: "bn-IN",
  //   language: "Bengali",
  // },
  {
    code: "en-US",
    language: "English",
  },
  {
    code: "gu-IN",
    language: "Gujarati",
  },
  {
    code: "hi-IN",
    language: "Hindi",
  },
  {
    code: "kn-IN",
    language: "Kannada",
  },
  {
    code: "mr-IN",
    language: "Marathi",
  },
  {
    code: "ta-IN",
    language: "Tamil",
  },
  {
    code: "te-IN",
    language: "Telugu",
  },
  {
    code: "es-ES",
    language: "Spanish",
  },
  {
    code: "es-AR",
    language: "Argentina Spanish",
  },
  {
    code: "de-DE",
    language: "German",
  },
];

class NewMeeting extends PureComponent {
  AUDIO_FILE_TAG = "audioFile";
  today = new Date().toISOString().split("T")[0];
  state = {
    title: "",
    audioFile: null,
    audioFileBlob: null,
    audioFileType: "",
    language: "en-US",
    recordedStartTime: undefined,
    date: this.today,
  };
  audioRef = createRef(null);
  mediaRecorder = null;
  chunks = [];
  componentDidMount() {
    if (this.audioRef.current) {
      this.audioRef.current.addEventListener(
        "loadedmetadata",
        this.updateDuration
      );
    }
  }

  componentWillUnmount() {
    if (this.audioRef.current) {
      this.audioRef.current.removeEventListener(
        "loadedmetadata",
        this.updateDuration
      );
    }
  }
  handleCheck = (e) => {
    this.setState({ checked: e.target.checked });
  };
  updateDuration = () => {
    if (this.audioRef.current) {
      this.setState({ duration: this.audioRef.current.duration * 1000 });
    }
  };
  startRecording = () => {
    if (!this.state.checked) {
      toast.error("Please provide your consent by checking the box.", {
        onClose: toast.dismiss(),
      });
      return;
    }
    this.chunks = [];
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.setState({ recordedStartTime: new Date() });
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.ondataavailable = (e) => {
          this.chunks.push(e.data);
        };
        this.mediaRecorder.start();
      })
      .catch((err) => {
        console.error("Error starting recording: " + err);
        toast.error("Error starting recording", {
          onClose: toast.dismiss(),
        });
      });
  };

  stopRecording = () => {
    this.mediaRecorder.stop();
    this.mediaRecorder.onstop = () => {
      const blob = new Blob(this.chunks, { type: "audio/wav" });
      this.setState({
        audioFileBlob: blob,
        audioFileType: "audio/wav",
        recordedStartTime: null,
        duration: new Date() - this.state.recordedStartTime,
      });
      this.chunks = [];
    };
  };

  onMeetingNameChange = (e) => this.setState({ title: e.target.value });
  onLangChange = (e) => this.setState({ language: e.target.value });
  onPatientNameChange = (e) => this.setState({ patientName: e.target.value });
  onPatientIdChange = (e) => this.setState({ patientId: e.target.value });
  onDateChange = (e) => {
    console.log(new Date(e.target.value));
    return this.setState({ date: e.target.value });
  };

  onFileChange = (e) => {
    const blob = new Blob([e.target.files[0]], {
      type: e.target.files[0].type,
    });
    this.setState({
      audioFile: e.target.files[0],
      audioFileBlob: blob,
      audioFileType: e.target.files[0].type,
    });
  };

  onUpload = () => {
    const {
      audioFile,
      title,
      language,
      audioFileBlob,
      patientId,
      patientName,
      date,
      duration,
      checked,
    } = this.state;
    if (!checked) {
      toast.error("Please provide your consent by checking the box.", {
        onClose: toast.dismiss(),
      });
      return;
    }
    if (!audioFileBlob || !title) {
      toast.error("Please enter meeting name and upload audio file", {
        onClose: toast.dismiss(),
      });
      return;
    }
    if (!patientId) {
      toast.error("Please enter patient id", {
        onClose: toast.dismiss(),
      });
      return;
    }
    if (!patientName) {
      toast.error("Please enter patient name", {
        onClose: toast.dismiss(),
      });
      return;
    }

    toast.success("Uploading Meeting", {
      onClose: toast.dismiss(),
    });
    const { dUploadMeeting } = this.props;
    const payload = {
      audioFile:
        audioFile ||
        new File([audioFileBlob], `${title}.wav`, { type: "audio/wav" }),
      title,
      patientId,
      patientName,
      meetingdate: date
        ? new Date(date).toISOString()
        : audioFile
        ? new Date(audioFile.lastModified).toISOString()
        : new Date().toISOString(),
      duration: isNaN(duration) ? 0 : duration,
      language,
    };
    dUploadMeeting(payload);
  };

  handleStartStop = () => {
    if (this.state.recordedStartTime) {
      this.stopRecording();
    } else {
      this.startRecording();
    }
  };

  clear = () => {
    this.setState({
      audioFile: null,
      audioFileBlob: null,
      audioFileType: "",
      recordedStartTime: undefined,
    });
  };

  render() {
    const {
      audioFile,
      audioFileBlob,
      audioFileType,
      recordedStartTime,
      language,
      date,
    } = this.state;
    return (
      <Card
        className="profile-card"
        style={{ backgroundColor: "#fff", boxShadow: "none" }}
      >
        <Card.Body>
          <Row className="mb-4">
            <Col>
              <FloatingLabel label="Date">
                <Form.Control
                  onChange={this.onDateChange}
                  type="date"
                  placeholder="dd-mm-yyyy"
                  defaultValue={date}
                />
              </FloatingLabel>
            </Col>
            <Col></Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <FloatingLabel label="Meeting name">
                <Form.Control
                  onChange={this.onMeetingNameChange}
                  type="text"
                  placeholder="Enter Meeting Name"
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel label="Patient name">
                <Form.Control
                  onChange={this.onPatientNameChange}
                  type="text"
                  placeholder="Enter Patient Name"
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <FloatingLabel label="Patient Id">
                <Form.Control
                  onChange={this.onPatientIdChange}
                  type="text"
                  placeholder="Enter Patient id"
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel label="Select language">
                <Form.Select value={language} onChange={this.onLangChange}>
                  {languages.map(({ code, language }) => (
                    <option key={code} value={code}>
                      {language}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>
          <div className="flex validate-input m-b-60 row justify-content-between">
            {audioFileBlob ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <audio
                  style={{ marginTop: 16 }}
                  id={this.AUDIO_FILE_TAG}
                  controls
                  ref={this.AUDIO_FILE_TAG}
                  src={URL.createObjectURL(audioFileBlob)}
                />
                <button
                  className={`rounded text-white m-l-24 p-t-5 p-b-5 p-l-10 m-r-24 p-r-10 pull-right ${"bg-danger"}`}
                  onClick={this.clear}
                >
                  Clear
                </button>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outline-info"
                  className="m-l-20 p-t-5 p-b-5 p-l-90 m-r-10 p-r-90  flex space-x-8 mt-3"
                  onClick={this.handleStartStop}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: "30px",
                    backgroundColor: "#6750A41F",
                    color: "#6750A4",
                    borderColor: "#6750A4",
                  }}
                >
                  <span style={{ padding: "4px 21px" }}>
                    {recordedStartTime === undefined ? "Record" : "Stop"}
                  </span>
                  {recordedStartTime === undefined ? null : (
                    <div
                      style={{ height: 16, width: 16 }}
                      className={`rounded m-l-8 ${
                        recordedStartTime === undefined ? "" : "recordingbtn"
                      }`}
                    >
                      {" "}
                    </div>
                  )}
                </Button>
                <span style={{ marginRight: 24, marginLeft: 24 }}>OR</span>
                <Form.Group className="mt-1">
                  {/* <Form.Label>Audio </Form.Label> */}
                  <Form.Control
                    size="lg"
                    type="file"
                    onChange={this.onFileChange}
                  />
                </Form.Group>
              </div>
            )}
          </div>
          <Row>
            <Col>
              <Form.Check
                inline
                label="By clicking here, everyone present in the room consent to this audio being recorded by Moss AI"
                name="group1"
                type="checkbox"
                onChange={(e) => this.handleCheck(e)}
              />
            </Col>
          </Row>
          <div className="container-login100-form-btn">
            <Button
              style={{ backgroundColor: "#6750A4" }}
              className="rounded-pill p-l-30 p-r-30 p-t-15 p-b-15"
              onClick={(e) => this.onUpload(e)}
              pill
            >
              Upload recording
            </Button>
          </div>
        </Card.Body>
      </Card>
    );
  }
}

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    dUploadMeeting: (formFields) => {
      dispatch(uploadMeeting(formFields));
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(NewMeeting);
