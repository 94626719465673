export const readStorage = (key) => localStorage.getItem(key);

export const writeStorage = (key, value) => {
    localStorage.setItem(key, value);
};

export const emptyStorage = (key) => {
    localStorage.removeItem(key);
};

export const getEndDate = (startDate, duration) => {
    let formatedDate = startDate;

    if (typeof startDate === "string") {
        const options = {
            year: "numeric",
            month: "short",
            day: "2-digit",
            weekday: "short",
            timeZone: "Asia/Kolkata",
        };
        let dt = new Date(startDate);
        let addDate = 365;
        if (duration === "MONTHLY") {
            addDate = 30;
        } else if (duration === "QUARTERLY") {
            addDate = 90;
        }
        dt.setDate(dt.getDate() + addDate);
        formatedDate = new Date(dt).toLocaleString("en-IN", options);
    }

    return formatedDate;
};

export const isPlanActive = (startDate, duration) => {
    const endDate = new Date(getEndDate(startDate, duration));
    const currentDate = new Date();
    return currentDate <= endDate;
};

export const isPlanPaid = (plan) => !!plan.price;

export const dateFormatter = (dbFormatDate) => {
    let formatedDate = dbFormatDate;
    const options = {
        year: "numeric",
        month: "short",
        day: "2-digit",
        weekday: "short",
//        timeZone: "Asia/Kolkata",
    };
    if (typeof dbFormatDate === "string") {
        formatedDate = new Date(dbFormatDate).toLocaleString("en-IN", options);
    }

    return formatedDate;
};

export const dateTimeFormatter = (dbFormatDate) => {
    let formatedDate = dbFormatDate;
    const options = {
        year: "numeric",
        month: "short",
        day: "2-digit",
        weekday: "short",
        hour: "numeric",
        minute: "numeric",
        timeZone: "Europe/Berlin",
    };
    if (typeof dbFormatDate === "string") {
        formatedDate = new Date(dbFormatDate).toLocaleString("en-IN", options);
    }

    return formatedDate;
};

export const moneyFormatter = (amount) => {

    var userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetails) {
        var country = userDetails.country;
        if (country != "IN") {
            var formatter = new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
            });
        } else {
            formatter = new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
            });
        }
    } else {
        formatter = new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "INR",
        });
    }


    return formatter.format(amount);
};

export const castToNumber = (number) => (number ? parseInt(number, 10) : 0);

export const replaceAll = (str, find, replace) =>
    str.replace(new RegExp(find, "g"), replace);

export const cleanUp = (st) => {
    if (typeof st === "string" && st) {
        let modStr = st
                .replace(/[^a-z0-9]+/gi, " ")
                .replace(/^-+/, "")
                .replace(/-+$/, "")
                .toLowerCase();
        return toTitleCase(modStr);
    }
    return st;
};

export const timeToText = (timeInMillSec) => {
    if (typeof timeInMillSec === "number" && timeInMillSec) {
        let milliseconds = Math.floor((timeInMillSec % 1000) / 100),
                seconds = Math.floor((timeInMillSec / 1000) % 60),
                minutes = Math.floor((timeInMillSec / (1000 * 60)) % 60),
                hours = Math.floor((timeInMillSec / (1000 * 60 * 60)) % 24);

        let returnText = "";
        if (hours) {
            hours = hours < 10 ? "0" + hours : hours;
            returnText += hours + " hr";
        }
        if (minutes) {
            minutes = minutes < 10 ? "0" + minutes : minutes;
            returnText += returnText ? ", " : "";
            returnText += minutes + " min";
        }
        if (seconds) {
            seconds = seconds < 10 ? "0" + seconds : seconds;
            returnText += returnText ? ", " : "";
            returnText += seconds + " sec";
        }

        if (milliseconds) {
            returnText += returnText ? ", " : "";
            returnText += milliseconds + " ms";
        }

        return returnText;
    }
    return 0;
};

export const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};
