import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { Button } from "react-bootstrap";
        const Header = ({
        logout,
                isPageReady,
                isLogin,
                name,
                showCart,
                profilePic,
        }) => {
const [isMenuOpen, setIsMenuOpen] = useState(false);
        const showMenu = () => {
setIsMenuOpen(true);
        };
        const hideMenu = () => {
setIsMenuOpen(false);
        };
        useEffect(() => {
        window.addEventListener("scroll", isSticky);
                window.addEventListener("scroll", isScrolledEnough);
                return () => {
        window.removeEventListener("scroll", isSticky);
                window.removeEventListener("scroll", isScrolledEnough);
        };
        });
        /* Method that will fix header after a specific scrollable */
        const isSticky = (e) => {
const header = document.querySelector(".header");
        const scrollTop = window.scrollY;
        scrollTop >= 50
        ? header.classList.add("header-scrolled")
        : header.classList.remove("header-scrolled");
        };
        const isScrolledEnough = (e) => {
const backToTop = document.querySelector(".back-to-top");
        const scrollTop = window.scrollY;
        scrollTop >= 100
        ? backToTop.classList.add("show")
        : backToTop.classList.remove("show");
        };
        return (
                <header id="header" className="header fixed-top">
    <div className="container-fluid container-xl">
        <div
            className="row justify-content-between"
            style={{ height: "100px" }}
            >
            <div className="col-auto align-self-center">                
                      
            </div>
            <div className="col-auto align-self-center">
                <nav
                    id="navbar"
                    className={!isMenuOpen ? "navbar" : "navbar  navbar-mobile"}
                    >
                    <ul>
<li>
                        <a className="logo d-block" href="https://profile.keepmytalk.ai/">
                            Home
                        </a>    
                        </li>

                        {isPageReady && isLogin ? (
                <>
                        <NavHashLink to="/profile" onClick={hideMenu}>
                            Profile
                        </NavHashLink>
                        <NavLink to="/">
                            <Button
                                className="me-2"
                                onClick={() => {
        logout();
                hideMenu();
        }}
                                >
                                Logout
                            </Button>
                        </NavLink>
                        </>
                        ) : (
                        <NavHashLink to="/login" onClick={hideMenu}>
                            <Button className="me-2">Login</Button>
                        </NavHashLink>
                        )}
                    </ul>

                <i
                        className={
                !isMenuOpen
                ? "bi bi-list mobile-nav-toggle"
                : "bi bi-x mobile-nav-toggle"
                        }
                        onClick={showMenu}
                        ></i>
        {isPageReady && isLogin && (
                <div className="col-auto align-self-center profile-pic">
                        <img
                            src={
                profilePic && profilePic.indexOf("undefined") === - 1
                ? profilePic
                : "https://www.w3schools.com/w3images/avatar2.png"
                            }
                            className="rounded-circle profile-pic-dashboard-small"
                            alt={`Welcome ${name}`}
                            />
                        <span>Welcome {name}</span>
                    </div>
                )}
        </nav>
                </div>
                </div>
                </div>
                <NavHashLink
        className="back-to-top d-flex align-items-center justify-content-center"
        to="/"
        >
        <i className="bi bi-arrow-up-short" />
    </NavHashLink>
                </header>
                );
};
export default Header;
