const tempEnv = {
  logoPath: "https://moss.simplibot.com/assets/img/logo.png",
};

switch (process.env.REACT_APP_ENV) {
  default:
  case "local":
    tempEnv.myEndPoint = "http://127.0.0.1:3001";
    tempEnv.keyId = "rzp_test_DWt4DOSiObuj9o";
    break;
  case "development":
    tempEnv.myEndPoint = "https://api.keepmytalk.ai";
    tempEnv.keyId = "rzp_test_DWt4DOSiObuj9o";
    break;
  case "production":
    tempEnv.myEndPoint = "https://be.simplibot.com";
    tempEnv.keyId = "rzp_test_DWt4DOSiObuj9o";
    break;
}

export const { myEndPoint, keyId, keySecret, logoPath } = tempEnv;
