import React, { useEffect } from "react";
import { useHistory,Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import {
  makeSelectAppPlanList,
  makeSelectAppCurrentSubscription,
} from "../App/selectors";
import { addToCart, getPlan, resetFlow } from "../App/actions";
import { INVESTORS } from "../App/internalConfig";

import HeroBanner from "../../components/HeroBanner";
import HowItWorks from "../../components/HowItWorks";
import Features from "../../components/Features";
import WhyMossAI from "../../components/WhyMossAI";
import Plans from "../../components/Plans";
import Resources from "../../components/Resources";
import Contact from "../../components/Contact";
import Gallery from "../../components/Gallery";
import OurInvestors from "../../components/OurInvestors";

const Home = ({
  dGetPlan,
  dAddToCart,
  isLogin,
  planList,
  isPageReady,
  dResetFlow,
  currentSubscription,
  userDetails
}) => {
  let history = useHistory();
  const moveToPage = (pagePath) => {
    history.push(pagePath);
  };
  useEffect(() => {
    if (isPageReady) {
      dGetPlan();
      dResetFlow();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageReady, isLogin]);
  if(isLogin) {
    return <Redirect to="/profile" />;
  }else{
      return <Redirect to="/login" />; 
  }
  return (
    <>
      <HeroBanner />
      {/* eslint-disable-next-line jsx-a11y/no-distracting-elements */}      
      <main id="main">
        <HowItWorks />
        <Features />
        <WhyMossAI />
        {planList && (
          <Plans
            plansAvailable={planList}
            addToCart={dAddToCart}
            isLogin={isLogin}
            moveToPage={moveToPage}
            userDetails={userDetails}
            isPlanActive={
              currentSubscription && currentSubscription[0]
                ? !currentSubscription[0].isTopUpAllowed &&
                  currentSubscription[0].isPlanActive
                : false
            }
          />
        )}        
        <Gallery />
        <OurInvestors investors={INVESTORS} />
        <Contact />
      </main>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  planList: makeSelectAppPlanList(),
  currentSubscription: makeSelectAppCurrentSubscription(),
});

function mapDispatchToProps(dispatch) {
  return {
    dGetPlan: () => {
      dispatch(getPlan());
    },
    dResetFlow: () => {
      dispatch(resetFlow());
    },
    dAddToCart: (plan) => dispatch(addToCart(plan)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Home);
